.union {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.union:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.union-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
