.union-index-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.union-index-container h2 {
  padding-top: 50px;
  padding-bottom: 40px;
  font-size: 4rem;
  font-weight: bold;
}

.union-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
