.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding: 20px;
  font-size: 16px;
  color: #666;
  border-top: 1px solid black;
}

.footer a {
  color: black;
  text-decoration: none;
  margin: 0 7px;
}

.footer a:hover {
  text-decoration: underline;
}

.footer span {
  color: black;
  font-weight: 700;
}

.tech-stack {
  margin-bottom: 10px;
}

.creators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}