.main-page {
  margin: 0;
  padding: 0;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 600px;
  width: 100%;
  background-image: url("https://we-union-id-photos.s3.amazonaws.com/public/backgrounds/pexels-life-matters-4613877.jpg");
  background-size: 110% auto;
  position: relative;
  border-bottom: 1px solid black;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.quote {
  width: 25%;
  padding: 80px;
  margin-right: 50px;
}

.words {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
}

.attribute {
  font-size: 18px;
  text-align: right;
  margin-top: 10px;
}

.main-page img {
  width: 50%;
  transform: translateY(25%);
  height: auto;
  padding-left: 40px;
}

.feature-1 {
  display: flex;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid black;
  background-color: rgb(45, 149, 235);
}

.feature-2 {
  display: flex;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid black;
  background-color: rgb(144, 172, 177);
}

.feature-3 {
  display: flex;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid black;
  background-color: rgb(194, 245, 242);
}

.feature-4 {
  display: flex;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid black;
  background-color: rgb(140, 238, 255);
}

.feature-1-left {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  align-items: center;
  margin-top: 100px;
}

.feature-1-right {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  font-style: italic;
}

.feature-2-left {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  font-style: italic;
}

.feature-2-right {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  align-items: center;
  margin-top: 50px;
}

.feature-3-left {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  align-items: center;
  margin-top: 100px;
}

.feature-3-right {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  font-style: italic;
}

.feature-4-left {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  font-style: italic;
}

.feature-4-right {
  box-sizing: border-box;
  display: block;
  flex-basis: 50%;
  max-width: 50%;
  padding: 54px;
  position: relative;
  width: 480px;
  text-align: center;
  align-items: center;
  margin-top: 50px;
}

.feature-title {
  font-family: Proxima Nova, sans-serif;
  font-size: 72px;
  font-weight: 100;
  line-height: 80px;
  margin-left: 100px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

li {
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-weight: 100;
  line-height: 30px;
  margin-left: 100px;
}

/* .features-list > li {
} */

.main-feature {
  font-family: Impact, sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.feature-details {
  font-size: 1em;
  margin-left: 20px;
  line-height: 1.8;
}

.feature-description {
  font-size: 20px;
  font-weight: 400;
}

.in-conclusion {
  font-family: Proxima Nova, sans-serif;
  padding: 50px;
  text-align: center;
  line-height: 1.5;
  display: flex;
  box-sizing: border-box;
  font-size: 44px;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid black;
  background-color: rgb(140, 238, 255);
}
