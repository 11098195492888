.union-name-container h1 {
  padding: 30px;
  font-size: 50px;
  font-weight: 600;
}

.union-form-container {
  display: flex;
  position: absolute;
  top: 25%;
  left: 30%;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 5px 45px rgba(0, 0, 0, 15);
  padding: 20px;
  backdrop-filter: blur(7px);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.union-label {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  text-shadow: 1px 1px #514f4f;
  margin-bottom: 10px;
}

.union-form-input {
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  color: #2d333f;
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  width: 60%;
  margin-top: 12px;
  margin-left: 20%;
  font-weight: 400;
}

.union-form-button {
  background-color: red;
  color: #fff;
  height: 45px;
  width: 60%;
  margin-left: 20%;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-top: 35px;
}

.union-form-button:hover {
  background-color: #312e2e;
  color: #fff;
  cursor: pointer;
}

.invite-container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  background-color: rgb(218, 253, 241);
  flex-direction: column;
  justify-content: left;
  width: 300px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
}

.invite-form {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.invite-input {
  margin-left: 10px;
  margin-right: 10px;
}
.invite-form label {
  font-size: 14px;
}

.invite-button {
  padding: 5px 10px;
  background-color: #1221ad;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.invite-button:hover {
  background-color: #333333;
}

.create-union-background {
  background-image: url(https://we-union-id-photos.s3.amazonaws.com/public/backgrounds/pexels-monstera-6238119.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
