.compose-grief-background {
  background-image: url(https://we-union-id-photos.s3.amazonaws.com/public/backgrounds/pexels-centre-for-ageing-better-9729879.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compose-grief-container {
  width: 800px;
  height: 400px;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 54%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  padding: 20px 35px;
}

.compose-grief {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
  width: 50%;
}

.compose-grief input[type="textarea"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.compose-grief input[type="submit"] {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.compose-grief input[type="submit"]:hover {
  background-color: #3e8e41;
}

.errors {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.gp,
.pg {
  text-align: left;
  font-size: 20px;
  padding-top: 10px;
}

.pp-grief-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* 
.gp {
  font-size: 28px;
  margin-bottom: 10px;
  
  text-transform: uppercase;
  margin-top: 20px;
}

.pg {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
} */ ;
