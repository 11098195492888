.page-container {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.profile-page-right-side {
  background-color: #d2e6fd;
  display: flex;
  margin-left: 100px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 230px;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  padding: 20px;
}

.profile-box {
  display: flex;
}

.member-title {
  flex: 1;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  padding-bottom: 10px;
}

.profile-image {
  height: 140px;
  width: 140px;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.union-card-button {
  padding: 10px 10px;
  margin-left: 80px;
  margin-top: 30px;
  background-color: #000000;
  color: white;
  border-radius: 14px;
  font-size: 24px;
  cursor: pointer;
}

.union-card-button:hover {
  background-color: #8f0000;
}

.profile-page-left-side {
  background-color: #c9f2fc;
  padding: 0 150px 50px 150px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.profile-page-left-side h1 {
  padding-top: 50px;
  padding-bottom: 25px;
  text-align: center;
  font-size: 30px;
  font-family: sans-serif;
}

.grief-delete-button {
  border-radius: 10px;
  background-color: #f32121;
  margin-left: 250px;
  transform: translateY(200%);
  color: white;
}

.grief-delete-button:hover {
  background-color: #9a3434;
}

.delete-account {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: red;
  border-radius: 10px;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-account:hover {
  background-color: #ff4d4d;
}

.other-unions-box {
  border-radius: 20px;
  background-color: #d5f5e3;
  padding: 20px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-top: 200px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

.select-button {
  background-color: #ffffff;
  border-radius: 20px;
  color: #000000;
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.select-button:hover {
  transform: scale(1.05);
}
