.all-grievances-show {
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.all-grievances-container {
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 450px;
}

.all-grievances-container > * {
  margin: 20px;
  width: 500px;
}

.all-grievances-container .grief-box {
  margin: 20px;
  width: 500px;
}

.all-grievances-container .grief-box h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.all-grievances-container .grief-box p {
  font-size: 16px;
}
