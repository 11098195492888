.grief {
  border: 1px solid rgb(59, 0, 0);
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  min-height: 80px;
  width: 500px;
  padding: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: rgb(221, 207, 230);
}

.grief-image {
  margin-right: 10px;
}

.grief-image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.grief-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h3 {
  font-size: smaller;
  margin: 0;
}

p {
  font-size: medium;
  margin: 5px 0;
}

h4 {
  font-size: small;
  margin: 10px 0;
}

.poll-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
