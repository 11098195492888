.login-background {
  background-image: url(https://we-union-id-photos.s3.amazonaws.com/public/backgrounds/pexels-andrea-piacquadio-3865557.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.signup-background {
  background-image: url(https://we-union-id-photos.s3.amazonaws.com/public/backgrounds/pexels-sora-shimazaki-5668838.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-container {
  height: 600px;
  width: 400px;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 54%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(7px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  padding: 20px 35px;
}

.session-form *{
  font-family: 'Poppins',sans-serif;
  color: white;
  letter-spacing: 0.5px;
  border: none;
}

.session-form h2{
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  margin-bottom: 20px;
  text-shadow: 1px 1px #4b4a4a;
}

.session-form label{
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 1px 1px #4b4a4a;
}

.session-form input{
  display: block;
  height: 40px;
  width: 96%;
  background-color: rgba(255,255,255,0.07);
  border-radius: 3px;
  padding: 0 8px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
}

.session-form input::placeholder{
  color: rgba(255,255,255,0.5);
}

.login-button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.errors {
  color: red;
  font-size: x-small;
}

.demo-button {
  background-color: rgba(0, 140, 200, 0.854);
  width: 100%;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
